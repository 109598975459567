/*
if ($('.filterRange').length > 0) {


// Initialisation des slider range
// var filterRange = document.getElementById('filterRange');
    var sliders = document.getElementsByClassName('filterRange');

    for (var i = 0; i < sliders.length; i++) {

        var min = parseInt(sliders[i].getAttribute('data-min'));
        var max = parseInt(sliders[i].getAttribute('data-max'));
        var start = parseInt(sliders[i].getAttribute('data-start'));
        var end = parseInt(sliders[i].getAttribute('data-end'));
        var datarange = JSON.parse(sliders[i].getAttribute('data-range').replace(/gu/g, '"'));
        datarange.min = min;
        datarange.max = max;

        noUiSlider.create(sliders[i], {
            start: [start, end],
            snap: true,
            connect: true,
            tooltips: true,
            //step: 1,
            format: {
                to: function (value) {
                    return value;
                },
                from: function (value) {
                    return value;
                }
            },
            range: datarange,
        });

        sliders[i].noUiSlider.on('change', addValuesRangeFacettes);
    }

    function addValuesRangeFacettes() {
        for (var i = 0; i < sliders.length; i++) {
            var key = sliders[i].getAttribute('data-key');
            var min = parseInt(sliders[i].getAttribute('data-min'));
            var max = parseInt(sliders[i].getAttribute('data-max'));
            var val_slider = sliders[i].noUiSlider.get();

            if (min >= val_slider[0] && max <= val_slider[1]) {
                val_slider[0] = '';
                val_slider[1] = '';
            }

            $('input[name=' + key + '_min]').val(val_slider[0]).change();
            $('input[name=' + key + '_max]').val(val_slider[1]).change();
        }
    }

}
*/