// Selectric est activé par défaut sur toutes les selects,
// mais nous on le veut pas avoir sur le sélecteur de langues en version tablette / mobile
$("#lang-dropdown-mobile").selectric('destroy');

// Ce script est nécessaire pour forcer le changement de page au click sur une option
$('#lang-dropdown-mobile').change(function () {
    var val = $(this).val();
    window.location.href = val;
});

$('.lang-dropdown > a').on('click', function(e){
    e.preventDefault();

    $(this).find('+ul').toggleClass("open");
});

// Gestion des menus de niveaux 2 en mobile
if (isMobileOrSmaller()) {
    /**
     * 1/ Prevent default sur le lien au click, ajout de la class submenu-active
     * 2/ Ajouter un bouton retour dans le submenu, enlève la class submenu active au click
     * 3/ Copier le lien principal dans le submenu
     * 4/ faire le css
     */
    $(".menu-item-has-children").each(function() {
        var link = this.querySelector("a");
        var submenu = this.querySelector(".sub-menu");

        console.log(link, submenu);

        if (link && submenu) {
            // Copie du premier lien pour l'append dans le submenu
            var linkCopy = link.cloneNode(true);
            var linkCopyList = document.createElement("li");
            linkCopyList.className = "menu-item";
            linkCopyList.appendChild(linkCopy);
            submenu.insertBefore(linkCopyList, submenu.firstChild);

            // Création du bouton retour
            var btnRetour = document.createElement("li");
            btnRetour.className = "btn-retour";

            btnRetour.addEventListener("click", function(e) {
                e.preventDefault();

                submenu.classList.remove("active");
            });

            submenu.insertBefore(btnRetour, submenu.firstChild);

            // PreventDefault sur le lien + affichage du submenu
            link.addEventListener("click", function(e) {
                e.preventDefault();

                submenu.classList.add("active");
            })
        }
    })
}