var calendarDateFormat = 'DD/MM/YYYY';
var calendarDateLanguage = $('html').data('lang');

/** Calendrier Formidable de base  */
$('.barre-listing-sit-agenda input.date').each(function () {
    if (!isTouchDevice()) {
        $(this).attr('type', 'text');
        $(this).attr('placeholder', '');
        $(this).dateRangePicker({
            inline: false,
            singleDate: false,
            alwaysOpen: false,
            singleMonth: false,
            showShortcuts: false,
            showTopbar: false,
            monthSelect: true,
            hoveringTooltip: false,
            yearSelect: [2020, 2030],
            startOfWeek: 'monday',
            customArrowPrevSymbol: '<span class="icon-ico-arrow-left"></span>',
            customArrowNextSymbol: '<span class="icon-ico-arrow-right"></span>',
            format: calendarDateFormat,
            language: calendarDateLanguage,
            container: '.barre-listing-sit-agenda .container',
            separator: ' to ',
            getValue: function () {
                if ($('#date_debut_agenda').val() && $('#date_fin_agenda').val())
                    return $('#date_debut_agenda').val() + ' to ' + $('#date_fin_agenda').val();
                else
                    return '';
            },
            setValue: function (s, s1, s2) {
                $('#date_debut_agenda').val(s1);
                $('#date_fin_agenda').val(s2);
            },
        });
    } else {
        $(this).attr('type', 'date');
    }
});

/** Gestion des calendriers de filtres synchronisé avec plage de dates */
var $rangeCalendarAlwaysOpen = $('#calendar-range');
var $rangeCalendarOverlayMobile = $('#calendar-range-mobile');
var $rangeInputFrom = $('[name=dr_du]', '#overlay-filtres');
var $rangeInputTo = $('[name=dr_au]', '#overlay-filtres');
//var $spefilters = $('[name=dr_spe]','#overlay-filtres');
var $rangeInputFromMea = $('[name=dr_du]', '.calendrier-listing-agenda');
var $rangeInputToMea = $('[name=dr_au]', '.calendrier-listing-agenda');
//var $spefiltersMea = $('[name=dr_spe]','.calendrier-listing-agenda');

// $spefilters.on('change',function () {
//     var data_date = $(this).data();
//     calendarListingData.set(data_date.start,data_date.end);
// })

$rangeInputFrom.on('change', function () {
    if (!$rangeInputFrom.val()) {
        $rangeInputFrom.val(moment(new Date()).format('YYYY-MM-DD'));
    }
    calendarListingData.set($rangeInputFrom.val(), $rangeInputTo.val());
});
$rangeInputTo.on('change', function () {
    if (!$rangeInputTo.val()) {
        $rangeInputTo.val(moment(new Date()).format('YYYY-MM-DD'));
    }
    calendarListingData.set($rangeInputFrom.val(), $rangeInputTo.val());
});
$rangeInputFromMea.on('change', function () {
    if (!$rangeInputFromMea.val()) {
        $rangeInputFromMea.val(moment(new Date()).format('YYYY-MM-DD'));
    }
    calendarListingData.set($rangeInputFromMea.val(), $rangeInputTo.val());
});
$rangeInputToMea.on('change', function () {
    if (!$rangeInputToMea.val()) {
        $rangeInputToMea.val(moment(new Date()).format('YYYY-MM-DD'));
    }
    calendarListingData.set($rangeInputFromMea.val(), $rangeInputToMea.val());
});


var calendarListingData = {

    from: null,
    to: null,
    submit: true,

    timeout: null,

    set: function (from, to, submit) {

        if (typeof submit != 'undefined') {
            calendarListingData.submit = submit;
        }

        if (from.match(/\//)) {
            from = from.split('/').reverse().join('-');
        }
        if (to.match(/\//)) {
            to = to.split('/').reverse().join('-');
        }
        if (from !== calendarListingData.from || to !== calendarListingData.to) {
            calendarListingData.from = from;
            calendarListingData.to = to;

            calendarListingData.onchange();
            return true;
        }
        return false;
    },

    onchange: function () {

        clearTimeout(calendarListingData.timeout);
        calendarListingData.timeout = setTimeout(function () {
            calendarListingData.onchangeDelais();
        }, 100);

    },

    onchangeDelais: function () {

        if (calendarListingData.from && calendarListingData.to) {
            $rangeInputFrom.val(calendarListingData.from);
            $rangeInputTo.val(calendarListingData.to);
            $rangeInputFromMea.val(calendarListingData.from);
            $rangeInputToMea.val(calendarListingData.to);
        }

        // force la soumission des filtres
        // if(calendarListingData.submit) {
        //     $rangeInputFrom.trigger('force-change');
        // }

        var fromSlash = calendarListingData.from.split('-').reverse().join('/');
        var toSlash = calendarListingData.to.split('-').reverse().join('/');

        if ($rangeCalendarOverlayMobile.length > 0) {
            $rangeCalendarOverlayMobile.data('dateRangePicker').setDateRange(fromSlash, toSlash);
        }
        if ($rangeCalendarAlwaysOpen.length > 0) {
            $rangeCalendarAlwaysOpen.data('dateRangePicker').setDateRange(fromSlash, toSlash);
        }


    }
};

var on_load_from = $rangeInputFrom.val();
var on_load_to = $rangeInputTo.val();

if (on_load_from && on_load_to) {
    calendarListingData.set(on_load_from, on_load_to, false);
    calendarListingData.submit = true;
}